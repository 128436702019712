<template>
  <div class="create-category-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Category' }"
        >類別管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>編輯類別</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <el-form
        ref="categoryForm"
        :model="category_form"
        :rules="category_rules"
        label-position="top"
      >
        <el-form-item label="中文名稱" prop="name_zh">
          <el-input
            v-model="category_form.name_zh"
            maxlength="64"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="英文名稱" prop="name_en">
          <el-input
            v-model="category_form.name_en"
            maxlength="64"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button type="success" @click="handleSubmit">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getCategory, updateCategory } from "@/api/category";

export default {
  name: "CreateCategory",
  data() {
    return {
      category_form: {
        name_zh: "",
        name_en: "",
      },
      category_rules: {
        name_zh: [
          { required: true, message: "請填入名稱", trigger: "blur" },
          { max: 64, message: "長度過長", trigger: "blur" },
        ],
        name_en: [
          { required: true, message: "請填入名稱", trigger: "blur" },
          { max: 64, message: "長度過長", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    categoryId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.handleGetCategory();
  },
  methods: {
    async handleGetCategory() {
      try {
        this.category_form = await getCategory(this.categoryId);
      } catch (e) {
        await this.$alert("輸入資料有誤，系統將強制跳轉頁面", "提醒", {
          confirmButtonText: "確定",
          type: "warning",
          callback: async () => {
            this.$message({
              type: "success",
              message: `跳轉成功`,
            });

            await this.handleRedirect();
          },
        });
      }
    },
    handleSubmit() {
      this.$refs.categoryForm.validate(async (valid) => {
        if (valid) {
          const cloneForm = { ...this.category_form };
          delete cloneForm.category_id;
          await updateCategory(this.categoryId, cloneForm);

          this.$message({
            type: "success",
            message: "編輯成功",
          });

          await this.handleRedirect();
        }
      });
    },
    async handleRedirect() {
      await this.$router.push({ name: "Category" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
